import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { IStatement, useStatementsContext } from "./StatementsContext";
import { IUser, useAuthUserContext } from "./UserContext";
import { immutable as unique } from "array-unique";

export type ManifestoId = string;
export interface IManifesto {
  uuid?: ManifestoId;
  creator?: IUser;
  statements: IStatement[];
  publicCount: number;
}

interface IManifestoContext {
  myManifesto?: IManifesto;

  setPublicCount: (publicCount: number) => void;

  add: (statement: IStatement) => void;
  remove: (statement: IStatement) => void;
  makeBookmark: (statement: IStatement) => void;
  contains: (statement: IStatement) => boolean;

  // reorder: (statement: IStatement, to: number) => void;
  reorder: (from: number, to: number) => void;

  reload: () => void;
}

const ManifestoContext = createContext<IManifestoContext | undefined>(
  undefined
);

export const ManifestoProvider: React.FC = ({ children }) => {
  const [statements, setStatements] = useState<IStatement[]>([]);
  const [publicCount, _setPublicCount] = useState<number>(0);
  const [myManifesto, setMyManifesto] = useState<IManifesto>();
  const [myManifestoUuid, setMyManifestoUuid] = useState<string>();
  const [needSave, setNeedSave] = useState(false);
  const { user, API } = useAuthUserContext();
  const { getStatement } = useStatementsContext();

  const setPublicCount = (publicCount: number) => {
    _setPublicCount(Math.min(Math.max(publicCount, 0), statements.length));
    setNeedSave(true);
  };

  const add = (statement: IStatement) => {
    setStatements((statements) => {
      return unique([ statement, ...statements]);
    });
    setNeedSave(true);
  };

  const makeBookmark = (statement: IStatement) => {
    setStatements((statements) => {
      setPublicCount(publicCount-1)
      // console.log("makeBookmark", statements, statement.id);
      let filteredStatements = statements.filter((stm) => statement.id !== stm.id);
      filteredStatements.splice(publicCount-1, 0, statement)
      return filteredStatements;
    });
    // setPublicCount(publicCount-1);
    setNeedSave(true);
  };

  const remove = (statement: IStatement) => {
    setStatements((statements) => {
      return statements.filter((stm) => statement !== stm);
    });
    setNeedSave(true);
  };

  const contains = (statement: IStatement): boolean => {
    return !!statements.find((other) => other.id === statement.id);
  };

  const reorder = (from: number, to: number) => {
    if (statements.length < from || statements.length < to) {
      return;
    }
    setStatements((statements) => {
      let result = [...statements];
      const movedItem = result.splice(from, 1);
      result.splice(to, 0, movedItem[0]);
      return result;
    });
    setNeedSave(true);
  };

  const processMyManifesto = useCallback(
    (data: any) => {
      setMyManifestoUuid(data.uuid);
      _setPublicCount(data.publicCount);
      if (data?.statements.length) {
        setStatements(
          data.statements
            .map((id: number) => {
              return getStatement(id);
            })
            .filter((item: any) => !!item)
        );
      } else {
        setStatements([]);
      }
    },
    [getStatement]
  );

  const reload = useCallback(async () => {
    // reload from API
    if(!user){
      return 
    }
    const res = await API?.get(`/manifesto/me`);
    if (res?.data) {
      //setMyManifesto(res.data);
      processMyManifesto(res.data);
    }
  }, [processMyManifesto, API]);

  const save = useCallback(async () => {
    const uuid = myManifestoUuid || "create";
    const postData = {
      publicCount: publicCount || 0,
      statements: statements.map((statement) => statement.id) || [],
    };
    const res = await API!.post(`/manifesto/${uuid}`, postData);
    if (res?.data) {
      processMyManifesto(res.data);
    }
  }, [myManifestoUuid, statements, publicCount, processMyManifesto, API]);

  useEffect(() => {
    setMyManifesto((manifesto) => {
      if (manifesto) {
        return { ...manifesto, uuid: myManifestoUuid, statements, publicCount };
      } else if (user) {
        return {
          uuid: myManifestoUuid,
          statements,
          publicCount,
          creator: user!,
        };
      } else {
        return manifesto;
      }
    });
  }, [statements, publicCount, myManifestoUuid, user]);

  useEffect(() => {
    reload();
  }, [user?.uuid, reload]);

  useEffect(() => {
    if (needSave) {
      setNeedSave(false);
      save();
    }
  }, [needSave, save]);

  return (
    <ManifestoContext.Provider
      value={{
        myManifesto,
        setPublicCount,
        add,
        remove,
        makeBookmark,
        contains,
        reorder,
        reload,
      }}
    >
      {children}
    </ManifestoContext.Provider>
  );
};

export const useManifestoContext = () => useContext(ManifestoContext)!;
