import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useAuthUserContext } from "../../context/UserContext";
// import useRandomStatement from "../../hooks/useRandomStatement";
import Blur from "../FX/Blur";
// import ColorShadow from "../FX/ColorShadow";
import styles from "./HomePage.module.css";
// import StrokeSvgFile from "../../assets/stroke.svg";
import { useManifestoContext } from "../../context/ManifestoContext";
import { useStatementsContext } from "../../context/StatementsContext";
import ParrotPngFile from "../../assets/LOGO-RIT-BLANC.png";

const HomePage: React.FC = () => {
  const { user } = useAuthUserContext();
  const { myManifesto } = useManifestoContext();
  // const randomStatement = useRandomStatement();
  const { stack } = useStatementsContext();

  useEffect(() => {}, []);

  if (user) {
    // if (!user.name || !user.avatar) {
    //   return <Redirect to="/manifesto/me" />;
    // }
    // if (myManifesto?.statements.length) {
    //   return <Redirect to="/manifesto/me" />;
    // }
    return <Redirect to={`/statement/${stack?.current?.id || 1}`} />;
  }

  return (
    <div className={styles.HomePage}>
      <img className={styles.Parrot} src={ParrotPngFile} alt="Realities in transition" />
      <p>
        Join us in writing the European XR manifesto.
      </p>

      {/* <Blur offset={[0, 20]} scale={1.1} opacity={0.33}>
        <Link
          to={`/statement/${stack?.current?.id ?? 1}`}
          className={styles.BrowseButton}
        >
          Have A Look
        </Link>
      </Blur> */}
      
      {/* <Blur offset={[0, 20]} scale={1.1} opacity={0.33}> */}
        <Link
          to={`/about`}
          className={styles.BrowseButton}
        >
          About
        </Link>
        <p></p>
        {/* <Link
          to={`/common-manifesto`}
          className={styles.BrowseButton}
        >
          Common Manifesto
        </Link>
        <p></p> */}
        <Link
          to={`/signup`}
          className={styles.BrowseButton}
        >
          Sign Up
        </Link>
        <p></p>
        <Link
          to={`/login`}
          className={styles.BrowseButton}
        >
          Log In
        </Link>
      {/* </Blur> */}

      {/* <Link to="/login" className={styles.AboutButton}>
        About
      </Link>
      <Link to="/login" className={styles.LoginButton}>
        Log in
      </Link>
      <Link to="/signup" className={styles.SignupButton}>
        Sign up
      </Link> */}
    </div>
  );
};

export default HomePage;
