import React, { useEffect, useState } from "react";

import styles from "./../Statement/StatementPage.module.css";
// import classnames from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";
import PageHeader from "../App/PageHeader";
import ContentSection from "../App/ContentSection";
import UserRow from "./../UserInterfaceElements/UserRow";
import {
  IUser,
  useUsersContext,
} from "../../context/UsersContext";
import {  } from "../../context/UsersContext";
import { useAuthUserContext } from "../../context/UserContext";
import { removeModerationClass } from "../Moderation/moderationFunctions";
import Breadcrumb from "../UserInterfaceElements/Breadcrumb";

const ModerateUsersPage: React.FC = () => {
  const { loggedIn, user } = useAuthUserContext();
  
  const { users } = useUsersContext();
  const history = useHistory();
  const [breadcrumb, setBreadcrumb] = useState<Array<JSX.Element>>([<></>]);

  users.sort((a, b) => {
    return (a.created < b.created) ? -1 : ((a.created > b.created) ? 1 : 0)
  }).reverse();
  
  useEffect(() => {
    window.location.pathname.substring(0, 9) === "/moderate" && setBreadcrumb([
      <Link to="/moderate/" >Moderation</Link>
    ]);
    users.sort((a, b) => {
      return (a.created < b.created) ? -1 : ((a.created > b.created) ? 1 : 0)
    }).reverse();
    const unlisten = history.listen((location, action) => {
      console.log('Route changed', { location, action });
      // Apply route change logic, i.e. dispatch to store
      if(location.pathname.substring(0, 9) !== '/moderate'){
        removeModerationClass();
      }
    });
    return unlisten;
  }, [users]);

  return (
    <div className={styles.ModerateUsersPage}>
      <Breadcrumb linkStack={breadcrumb} />
      <PageHeader>Moderate Users</PageHeader>
      <ContentSection>
        <p style={{color: "#FAF0E6" }}>Edit & remove users from the app.</p>
        {
          users.map((dbUser, index) => {
            //const updatedDate = new Date(user.updated);
            // return <Link to={`/moderate/${user.uuid}`} style={{marginTop:0}}>
            return user && user.uuid !== dbUser.uuid ? <UserRow user={dbUser} linkToUserPage={false} /> : <></>
            // </Link>
            }
          )
        }
      </ContentSection>
    </div>
  );
};

export default ModerateUsersPage;
