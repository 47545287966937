import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Link, useParams } from "react-router-dom";
import { useAuthUserContext, UserID } from "../../context/UserContext";
import ContentSection from "../App/ContentSection";
import PageHeader from "../App/PageHeader";

import { useManifestoContext } from "../../context/ManifestoContext";
import stylesManifesto from "./../Manifesto/ManifestoPage.module.css";
import cx from "classnames";
import { addModerationClass, removeModerationClass } from "./moderationFunctions";

import styles from "./ModerationPage.module.css";


const ModerationPage: React.FC = () => {
  const { user: loggedInUser } = useAuthUserContext();
  

  //const { id } = useParams<IRouteParams>();
  const { add, contains } = useManifestoContext();
  
  const history = useHistory();

  useEffect(() => {
    addModerationClass();
    const unlisten = history.listen((location, action) => {
      console.log('Route changed', { location, action });
      // Apply route change logic, i.e. dispatch to store
      if(location.pathname.substring(0, 9) !== '/moderate'){
        removeModerationClass();
      }
    });
    return unlisten;
  }, [])


  return (
    <div className={styles.ModerationPage}>
      {/* <PageHeader>Profile</PageHeader> */}
      

      <div className={stylesManifesto.ModerationPage}>
        <PageHeader className={cx(stylesManifesto.PageHeader, styles.PageHeader)}>
          Moderation
        </PageHeader>

        <ContentSection className={styles.ContentSection}>
          <p style={{color: "#FAF0E6" }}>
            What content would you like to moderate?
          </p>
          <ul>
            <li>
                <Link to="/moderate/statements">Moderate statements & reactions</Link>
            </li>
            <li>
                <Link to="/moderate/users">Moderate users</Link>
            </li>
            <li>
                <Link to="/moderate/popups">Moderate popup</Link>
            </li>
          </ul>
        </ContentSection>
      </div>
    </div>
  );
};

export default ModerationPage;
