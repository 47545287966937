import React, { useEffect, useState } from "react";

import styles from "./AddStatementPage.module.css";
// import classnames from "classnames";
import { useHistory } from "react-router-dom";
import useAddStatement from "../../hooks/useAddStatement";
import { useManifestoContext } from "../../context/ManifestoContext";
import useManifesto from "../../hooks/useManifesto";
import PageHeader from "../App/PageHeader";
import ContentSection from "../App/ContentSection";

const AddStatementPage: React.FC = () => {
  const [text, setText] = useState<string>("");
  const [source, setSource] = useState<string>("");
  const [sourceAuthor, setSourceAuthor] = useState<string>("");
  const [sourceLink, setSourceLink] = useState<string>("");
  // const [addToMyManifesto, _setAddToMyManifesto] = useState(true);
  const addToMyManifesto = true;
  const { add, statement } = useAddStatement();
  const { add: manifestoAdd, setPublicCount, myManifesto } = useManifestoContext();
  const { manifesto } = useManifesto( myManifesto && myManifesto.uuid ? myManifesto.uuid : "" );
  const history = useHistory();

  const maxStatementLength = 280;

  useEffect(() => {
    if (statement) {
      if (addToMyManifesto && manifesto) {
        setPublicCount(manifesto.publicCount + 1);
        manifestoAdd(statement);
      }
      history.push(`/statement/${statement.id}`);
    }
  }, [statement, addToMyManifesto, manifestoAdd, history]);

  return (
    <div className={styles.AddStatementPage}>
      <PageHeader>Contribute Statement</PageHeader>
      <ContentSection>
        <p>Write a new statement, others can also add it to their manifesto.</p>
        <textarea value={text} onChange={(evt) => setText(evt.target.value)} maxLength={maxStatementLength} />
        <p style={text.length === maxStatementLength ? {color: "red" } : {} }>{text.length} / {maxStatementLength} characters</p>
        <label>
          Source (optional)
          <input
            placeholder="Source"
            value={source}
            maxLength={140}
            onChange={(evt) => setSource(evt.target.value)}
          />
        </label>
        <label>
          Source Author (optional)
          <input
            placeholder="Source Author"
            value={sourceAuthor}
            maxLength={64}
            onChange={(evt) => setSourceAuthor(evt.target.value)}
          />
        </label>
        <label>
          Source link (optional)
          <input
            placeholder="URL"
            value={sourceLink}
            onChange={(evt) => setSourceLink(evt.target.value)}
          />
        </label>
        {/* <label>
          Add to my manifesto
          <input
            type="checkbox"
            checked={addToMyManifesto}
            onChange={(evt) => setAddToMyManifesto(evt.target.checked)}
          />
        </label> */}
        <button disabled={!text} onClick={() => add(text.replace(new RegExp("(^(\"|')*|(\"|')*$)*", "g"), ""), source, sourceLink, sourceAuthor)}>
          Add statement to the pool
        </button>
      </ContentSection>
    </div>
  );
};

export default AddStatementPage;
