import React, { useEffect } from "react";

import styles from "./Menu.module.css";
import classnames from "classnames";
import { useMenuContext } from "../../context/MenuContext";
import { useAuthUserContext } from "../../context/UserContext";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
// import Avatar from "../User/Avatar";
// import useRandomStatement from "../../hooks/useRandomStatement";
import { useStatementsContext } from "../../context/StatementsContext";
import { BackFxProvider } from "../../context/BackFxContext";
import cx from "classnames";

interface IProps {
  className?: string;
}

const Menu: React.FC<IProps> = ({ className, children }) => {
  const { showMenu, setShowMenu } = useMenuContext();
  const { loggedIn, user } = useAuthUserContext();
  // const match = useRouteMatch<{ id: string }>("/statement/:id");
  // let curStatementId = undefined;
  // if (match?.params.id) {
  //   curStatementId = parseInt(match.params.id);
  // }
  // const randomStatement = useRandomStatement(curStatementId);
  const { stack } = useStatementsContext();
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      setShowMenu(false);
    });
  }, [history, setShowMenu]);

  return (
    <div
      className={classnames(className, styles.Menu, {
        [styles.Show]: showMenu,
      })}
      // onClick={() => setShowMenu(false)}
    >
    <BackFxProvider backdropClassName={styles.Backdrop}>
      <div className={styles.List}>
        <div>
          <Link to={`/common-manifesto`} 
          >
            Common Manifesto
          </Link>
        </div>
        <div>
          <Link to="/about" 
          >
            About
          </Link>
        </div>
        <div>
          <Link to={`/statement/${stack?.current?.id ?? 1}`}  
          >
            Browse Statements
          </Link>
        </div>
        {!loggedIn ? (
          <div className={styles.AccountMenuItem}>
            <Link to="/login">
              Account
            </Link>
          </div>
        ):null}
        {loggedIn ? (
          <>
            <div>
              <Link to="/statement/add">
                Contribute Statement
              </Link>
            </div>
            <div>
              <Link to="/manifesto/me" >
                My Manifesto
              </Link>
            </div>
            {/* <div>
              <Link to="/dashboard">Dashboard</Link>
            </div> */}
            <div className={styles.AccountMenuItem}>
              <Link to="/account/me"> 
                Account
              </Link>
            </div>
            {/* <div>
              <Link to="/logout">Logout</Link>
            </div> */}
          </>
        ) : null}
        {loggedIn && user && user.moderator ? (
           <div>
             <Link to="/moderate">
              Moderate Content
             </Link>
           </div>
        ):null}
      </div>
      </BackFxProvider>
    </div>
  );
};

export default Menu;
