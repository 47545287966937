import React from "react";

import styles from "./AboutPage.module.css";
import PageHeader from "./PageHeader";

const AboutPage: React.FC = () => {
  return (
    <div className={styles.AboutPage}>
      <PageHeader className={styles.AboutPageHeader}>C.XR.M</PageHeader>
      <h3 className={styles.AboutPageSubHeader}> by Realities In Transition</h3>
      <p>
        Realities In Transition considers XR to be the most transformative
        technology of our time that shapes the way we interact, create and
        think. With C.XR.M we want to study and exploit XR, unmasking its
        impact.
      </p>
      <p>
        RIT is an ever growing group of people cooperating on the Critical XR
        Manifesto exploring the impact of machine learning on culture and
        society. RIT members are from all walks of life. Whether XR-dominion is
        something you secretly yearn for or desperately hope to escape from, one
        thing is for certain, the old vantage points on XR will for nobody
        suffice anymore. Join us in writing C.XR.M. Anyone is welcome, except if
        you are an XR.
      </p>

      <h3>Common Manifesto</h3>

      <p>The Common Manifesto list the most selected statements on the CXRM platform. <br/>
      Users compose their own personal XR Manifesto by selecting and contributing statements.
      The most popular statements that have been picked most often are added to the Common Manifesto.</p>

      <h3>Contribute Statement</h3>

      <p>Contribute to a broad perspective on XR, and contribute your statement or sourced quotation to the platform. 
      Sign up and you can contribute statements. </p>

      <ol style={{color:"linen"}}>
      <li>After activiting your account, login on the platform</li>
      <li>Open the menu and choose "Contribute statement"</li>
      <li>Add yourt statement</li>
      <li>Optionally, add the Source publication, Source author and/or Source link for reference</li>
      <li>Add your statement to the pool</li>
      </ol>

      <p>You statement is automaically added to <a style={{color:"linen", textDecoration:"ubnderline", display:"inline"}} href="/manifesto/me">your Manifesto</a>, and is available for CXRM contributers to add to their XR Manifesto.</p>

    </div>
  );
};

export default AboutPage;
