import React from "react";
import { useParams } from "react-router-dom";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

import { useManifestoContext } from "../../context/ManifestoContext";
import { IStatement } from "../../context/StatementsContext";
import { useAuthUserContext } from "../../context/UserContext";
import useManifesto from "../../hooks/useManifesto";

import styles from "./ManifestoPage.module.css";

import { ReactComponent as BarDragIcon } from "../../assets/icon_bar_drag.svg";
import { ReactComponent as StatementDragIcon } from "../../assets/icon_statement_drag.svg";
import { ReactComponent as AddToManifestoIcon } from "../../assets/icon_addToManifesto.svg";
import StatementRow from "./StatementRow";
import PageHeader from "../App/PageHeader";
import ShareButton from "../Share/ShareButton";

interface IRouteParams {
  id: string;
}

const ManifestoPage: React.FC = () => {
  const { id } = useParams<IRouteParams>();
  const { setPublicCount, reorder, add, contains } = useManifestoContext();
  const { user: loggedInUser } = useAuthUserContext();
  const { manifesto } = useManifesto(id);

  const isMyManifesto =
    loggedInUser && manifesto?.creator?.uuid === loggedInUser?.uuid;

  if (!manifesto) {
    return <div>loading....</div>;
  }

  let publicManifesto = manifesto.statements || [];
  let privateManifesto: IStatement[] = [];
  if (isMyManifesto) {
    publicManifesto = manifesto.statements.slice(0, manifesto.publicCount);
    privateManifesto = manifesto.statements.slice(manifesto.publicCount);
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    let sourceIdx = result.source.index;
    if (result.source.droppableId === "private") {
      sourceIdx += publicManifesto.length;
    }
    let destinationIdx = result.destination.index;
    if (result.destination.droppableId === "private") {
      destinationIdx += publicManifesto.length;
    } else if (result.destination.droppableId === "drop-sep") {
      destinationIdx = publicManifesto.length;
    }
    if (result.source.droppableId === "drop-sep") {
      setPublicCount(destinationIdx);
    } else {
      reorder(sourceIdx, destinationIdx);
      if (result.destination.droppableId === "drop-sep") {
        if (
          result.destination.index === 0 &&
          result.source.droppableId === "private"
        ) {
          setPublicCount(manifesto.publicCount + 1);
        } else if (
          result.destination.index === 1 &&
          result.source.droppableId === "public"
        ) {
          setPublicCount(manifesto.publicCount - 1);
        }
      } else if (result.destination.droppableId !== result.source.droppableId) {
        if (result.destination.droppableId === "public") {
          setPublicCount(manifesto.publicCount + 1);
        } else {
          setPublicCount(manifesto.publicCount - 1);
        }
      }
    }
  };

  const makeStatement = (statement: IStatement, idx: number) => (
    !statement.deleted ? <li key={idx} className={styles.ManifestoItem}>
      <StatementRow
        statement={statement}
        textClassName={styles.StatementText}
      />
      {loggedInUser && (
        <span>
          {contains(statement) ? (
            <>in your manifesto</>
          ) : (
            <span onClick={() => add(statement)}>
              <AddToManifestoIcon />
            </span>
          )}
        </span>
      )}
    </li>: false
  );

  const makeDraggableStatement = (statement: IStatement, idx: number, publicStm:boolean) => (
    !statement.deleted ? <Draggable draggableId={`${statement.id}`} index={idx} key={statement.id}>
      {(provided) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={styles.ManifestoItem}
        >
          <StatementRow
            statement={statement}
            textClassName={styles.StatementText}
            isPublic={ publicStm }
          />
          <span className={styles.ManifestoDrag}>
            <StatementDragIcon />
          </span>
        </li>
      )}
    </Draggable> : null
  );

  return (
    <div className={styles.ManifestoPage}>
      <PageHeader className={styles.PageHeader}>
        {isMyManifesto
          ? "My Manifesto"
          : `Manifesto of ${manifesto.creator?.name}`}
      </PageHeader>
      <ShareButton
        text={`Check this Critical XR manifesto ${
          manifesto.creator?.name ? `of ${manifesto.creator?.name}` : ""
        }`}
        url={
          isMyManifesto
            ? window.location.href.replace(/me$/, manifesto.uuid ?? "me")
            : undefined
        }
        className={styles.ShareButton}
      />
      {!isMyManifesto && <ul>{publicManifesto.map(makeStatement)}</ul>}
      {isMyManifesto && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="public">
            {(provided) => (
              <ul ref={provided.innerRef} {...provided.droppableProps}>
                {publicManifesto.map((stm, idx) => makeDraggableStatement(stm, idx, true))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
          <Droppable droppableId="drop-sep">
            {(provided) => (
              <ul ref={provided.innerRef} {...provided.droppableProps}>
                <Draggable draggableId="drag-sep" index={0}>
                  {(provided) => (
                    <li
                      className={styles.PublicCountSeparator}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <label>My Manifesto</label>
                      <button
                        disabled={manifesto.publicCount <= 0}
                        onClick={() =>
                          setPublicCount(manifesto.publicCount - 1)
                        }
                      ></button>
                      <hr />
                      <label>Bookmarks</label>
                      <button
                        disabled={
                          manifesto.publicCount >= manifesto.statements.length
                        }
                        onClick={() =>
                          setPublicCount(manifesto.publicCount + 1)
                        }
                      ></button>
                      <span>
                        <BarDragIcon />
                      </span>
                    </li>
                  )}
                </Draggable>
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
          <Droppable droppableId="private">
            {(provided) => (
              <ul ref={provided.innerRef} {...provided.droppableProps}>
                {privateManifesto.map((statement, idx) =>
                  makeDraggableStatement(statement, idx, false)
                )}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
};

export default ManifestoPage;
