import React, { useEffect, useState } from "react";

import styles from "./AddStatementPage.module.css";
// import classnames from "classnames";
import { Link,useHistory, useParams } from "react-router-dom";
import useModerateStatement from "../../hooks/useModerateStatement";
import useStatement from "../../hooks/useStatement";
import { useManifestoContext } from "../../context/ManifestoContext";
import PageHeader from "../App/PageHeader";
import ContentSection from "../App/ContentSection";
import { removeModerationClass } from "../Moderation/moderationFunctions";
import Breadcrumb from "../UserInterfaceElements/Breadcrumb";

interface IRouteParams {
  id: string;
}

const AddStatementPage: React.FC = () => {
  const { id } = useParams<IRouteParams>();
  const statement = useStatement(parseInt(id));
  
  const [text, setText] = useState<string>("");
  const [deleted, setDeleted] = useState<boolean>(false);
  const [source, setSource] = useState<string>("");
  const [sourceAuthor, setSourceAuthor] = useState<string>("");
  const [sourceLink, setSourceLink] = useState<string>("");
  const [breadcrumb, setBreadcrumb] = useState<Array<JSX.Element>>([<></>]);
  // const [addToMyManifesto, _setAddToMyManifesto] = useState(true);
  const addToMyManifesto = true;
  const { moderate } = useModerateStatement();
  const { add: manifestoAdd } = useManifestoContext();
  const history = useHistory();

  const maxStatementLength = 280;
  //console.log(statement);


  useEffect(() => {
    if (statement !== undefined) {
      setText(statement.text);
      statement.source && setSource(statement.source);
      statement.sourceAuthor && setSourceAuthor(statement.sourceAuthor);
      statement.sourceLink && setSourceLink(statement.sourceLink);
      statement.deleted && setDeleted(statement.deleted ? true : false);
    //   if (addToMyManifesto) {
    //     manifestoAdd(statement);
    //   }
    //   history.push(`/statement/${statement.id}`);

      window.location.pathname.substring(0, 9) === "/moderate" && setBreadcrumb([
        <Link to="/moderate/" >Moderation</Link>, 
        <Link to="/moderate/statements" >Statements</Link>
      ]);
      const unlisten = history.listen((location, action) => {
        console.log('Route changed Yeah', { location, action }, location.pathname.substring(0, 9));
        // Apply route change logic, i.e. dispatch to store
        if(location.pathname.substring(0, 9) !== '/moderate'){
          removeModerationClass();
        }
      });
      return unlisten;
    }
  }, [statement, addToMyManifesto, manifestoAdd, history]);

  return (
    <div className={styles.AddStatementPage}>
      <Breadcrumb linkStack={breadcrumb} />
      <PageHeader>
        Moderate Statement
      </PageHeader>
      <ContentSection>
        <p>Write a new statement, others can also add it to their manifesto.</p>
        <textarea value={text} onChange={(evt) => setText(evt.target.value)} maxLength={maxStatementLength} />
        <p style={text.length === maxStatementLength ? {color: "red" } : {} }>{text.length} / {maxStatementLength} characters</p>
        <label>
          Source (optional)
          <input
            placeholder="Source"
            value={source}
            onChange={(evt) => setSource(evt.target.value)}
            maxLength={128}
          />
        </label>
        <label>
          Source Author (optional)
          <input
            placeholder="Source Author"
            value={sourceAuthor}
            maxLength={96}
            onChange={(evt) => setSourceAuthor(evt.target.value)}
          />
        </label>
        <label>
          Source link (optional)
          <input
            placeholder="URL"
            value={sourceLink}
            onChange={(evt) => setSourceLink(evt.target.value)}
          />
        </label>
        <label>
          Deleted:
          <input
            type="checkbox"
            checked={deleted}
            value="1"
            onChange={(evt) => setDeleted(evt.target.checked)}
            style={{width:"auto"}}
          />
        </label>
        {/* <label>
          Add to my manifesto
          <input
            type="checkbox"
            checked={addToMyManifesto}
            onChange={(evt) => setAddToMyManifesto(evt.target.checked)}
          />
        </label> */}

        {statement && <button disabled={!text} onClick={() => {
          moderate( id, text.replace(new RegExp("(^(\"|')*|(\"|')*$)*", "g"), ""), source, sourceLink, deleted, sourceAuthor); history.goBack(); 
        }} >
          Update statement
        </button>}
      </ContentSection>
    </div>
  );
};

export default AddStatementPage;
